.step-title {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.action-disabled-section {
  pointer-events: none;
  filter: opacity(0.6);
}

.job-header-text {
  color: #339af6;
  font-weight: 500;
  padding-right: 15px;
}

.page-no {
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
}

.email-title {
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
}

.email-text {
  font-size: 16px;
  padding-left: 10px;
}

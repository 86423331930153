@import 'static/style/2-pages/page.css';
@import 'static/style/2-pages/common.css';
.page-no {
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
}
.email-title {
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
}
.email-text {
  font-size: 16px;
  padding-left: 10px;
}
.tab-render-fail {
  margin: 15vh;
  font-size: 20px;
  font-weight: 600;
}
.zoom-btn {
  margin-left: 10vh;
  margin-right: 2px;
}
.page-desc {
  margin: 0 10px;
}
.tab-panel {
  margin-top: -60px;
}
.reinfer-disable {
  cursor: not-allowed;
  pointer-events: none;
}
.update-inference {
  font-size: 12px;
  color: #1890ff;
  padding-left: 10px;
  padding-top: 2px;
}
.ant-col.verification-sidebar {
  border-right: 1px solid #1b1922;
  display: flex;
  flex-direction: column;
}
.ant-col.verification-sidebar .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content.ant-tabs-card-content {
  overflow: auto;
}
.field-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  color: #00000099;
  transition: color 0.1s ease-in-out;
  cursor: pointer;
}
.field-wrapper > .field {
  width: 100%;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
.field-wrapper > .field .overlay {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;
}
.field:hover .overlay {
  background-color: black;
  color: #fff;
  opacity: 0.5;
}
.field-wrapper > .field.source-active {
  background-color: #0c0d0e;
  color: #cecbcb;
}
.field-active {
  border: 1px solid #2f72ff;
  line-height: normal;
}
.field-active textarea {
  position: relative;
  top: -5px;
}
.field.source-active {
  line-height: normal;
}
.field.source-active > textarea {
  border: 1px solid #2f72ff;
  position: relative;
  top: -5px;
}
.field.source-active > button {
  background: #2f72ff;
  color: #fff;
}
.field-wrapper .field-label {
  width: 25%;
}
.field-active .ant-select {
  width: 100%;
}
.field-wrapper > .field.active {
  font-size: 1rem;
  background-color: #2b2e31;
  opacity: 0.9;
  color: #cecbcb;
}
.field-wrapper > .field.active .field-input {
  border: none;
  box-shadow: none;
  width: 100%;
}
.field-wrapper > .field.active .field-input .ant-select-selection-selected-value {
  width: 100%;
}
.field-wrapper > .field.active input {
  border: none;
  flex: 1 1;
  box-shadow: none;
}
.field-wrapper > .field.active input.ant-input-number-input {
  padding-right: 30px;
}
.field-column {
  width: 33%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}
.action-menu {
  justify-content: center;
  display: flex;
  padding: 5px;
  position: absolute;
  bottom: 0;
  width: 30%;
  border-top: #bfbaba;
}
.other-form-fields {
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-react-container .ant-input-number-sm {
  width: 88px;
}
.document-alerts {
  display: flex;
}
.pspdf-container iframe {
  pointer-events: all;
}
.pspdf-container.resizing iframe {
  pointer-events: none;
}
.resize-handle {
  z-index: 200;
  left: calc(50% - 10px);
  background-color: red;
  height: 10px;
  width: 20px;
  position: absolute;
  cursor: row-resize;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background-image: linear-gradient(90deg, #48c6ef, #6f86d6);
}
.resize-handle svg {
  fill: rgba(255, 255, 255, 0.15);
  position: absolute;
  top: -7px;
  width: 20px;
}
.ant-table-wrapper.noPaddingTable .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-thead > tr > th {
  padding: 0px !important;
}
.ant-table-wrapper.smallPaddingTable .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-thead > tr > th {
  padding: 5px !important;
}
.small-font-table .ant-table-row > tr {
  font-size: 12px;
  height: 50px !important;
}
.zebra-table .ant-table-row:nth-child(even) {
  background: rgba(204, 204, 204, 0.15);
}
.bold-table-header .ant-table-thead > tr > th {
  background: white;
  font-weight: bold;
}
.noPaddingTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 0px !important;
}
.small-padding-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 1px !important;
}
.ant-table-wrapper.small-width-selection .ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  min-width: 35px !important;
}
table.bordered,
table.bordered th,
table.bordered td {
  border: 1px solid black;
}
table.template,
table.template th,
table.template td {
  border: 1px solid black;
  border-collapse: collapse;
  vertical-align: top;
}
table.template,
table.a4 {
  /*width : 827px;*/
  width: 100%;
  height: 1169px;
  border: 1px solid #ccc;
}
.border-left {
  border-left: 1px solid lightgrey;
}
table.doc-template,
table.doc-template tr,
table.doc-template td {
  border: 1px solid black;
  border-collapse: collapse;
  vertical-align: top;
}
table.doc-template td:hover {
  background-color: #fffc82;
  cursor: pointer;
}
table.product-table td {
  padding: 0 !important;
}
tr.product-list td:not(:last-child) {
  border-bottom: none;
}
.ant-table-bordered.no-wrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.editable-cell {
  position: relative;
}
.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}
.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}
.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}
.editable-cell-icon {
  line-height: 18px;
  display: none;
}
.editable-cell-icon-check {
  line-height: 28px;
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}
.editable-add-btn {
  margin-bottom: 8px;
}
.zui-table {
  border: none;
  border-right: solid 1px #ddefef;
  border-collapse: separate;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}
.zui-table thead th {
  background-color: #ddefef;
  border: none;
  color: #336b6b;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}
.zui-table tbody td {
  border-bottom: solid 1px #ddefef;
  color: #333;
  padding-right: 10px;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}
.table-head-word-break .ant-table-bordered .ant-table-thead > tr > th,
.table-head-word-break .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
  word-break: break-word;
}
.customer-ant-footer-height .ant-table-footer {
  height: 45px;
}
.ant-header-font-13 .ant-table-thead > tr > th {
  font-size: 13px;
}
.ant-table-padding-left .ant-table-tbody > tr > td {
  padding-left: 16px !important;
}
.strip-table tbody tr:nth-child(even) td {
  background-color: #f5f5f57d;
}
.scrollable-table {
  border: none;
  border-collapse: separate;
  border-spacing: 0;
}
.scrollable-table thead th {
  border: none;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
  background-color: #f5f5f57d;
}
.scrollable-table tbody td {
  padding-right: 10px;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}
.table-color {
  background-color: #f5f5f57d;
}
.other-charges-footer :global .ant-table-footer {
  padding: 5px !important;
}
.custom-strip-table > div.row:nth-child(odd) > div.col {
  background-color: #f5f5f57d;
}
.table-header-name {
  width: 10vw;
  padding: 8px;
  font-size: 14px;
}
.table-background {
  background-color: white;
}
.colgroup-1 {
  width: 10vw;
  min-width: 10vw;
}
.colgroup-2 {
  width: 30vw;
  min-width: 30vw;
}
.colgroup-3 {
  width: 35vw;
  min-width: 35vw;
}
.thead-title {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: #2b2b2a;
}
.ant-dropdown-menu.profile-menu {
  width: 300px;
  padding: 20px 0;
}
.ant-dropdown-menu.profile-menu > li {
  padding-left: 20px;
  padding-right: 20px;
}
.ant-card-small-padding .ant-card-body {
  padding: 10px !important;
}
.ant-table-wrapper.noPaddingTable .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-thead > tr > th {
  padding: 0px !important;
}
.ant-table-wrapper.smallPaddingTable .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-thead > tr > th {
  padding: 5px !important;
}
.small-font-table .ant-table-row {
  font-size: 12px !important;
}
.bold-table-header .ant-table-thead > tr > th {
  font-weight: bold !important;
}
.noPaddingTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 0px !important;
}
.small-padding-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 7px !important;
  background: white !important;
}
.ant-table-wrapper.small-width-selection .ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  min-width: 35px !important;
}
table.bordered,
table.bordered th,
table.bordered td {
  border: 1px solid black !important;
}
table.template,
table.template th,
table.template td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
  vertical-align: top !important;
}
table.template,
table.a4 {
  /*width : 827px;*/
  width: 100% !important;
  height: 1169px !important;
  border: 1px solid #ccc !important;
}
.border-left {
  border-left: 1px solid lightgrey !important;
}
table.doc-template,
table.doc-template tr,
table.doc-template td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
  vertical-align: top !important;
}
table.doc-template td:hover {
  background-color: #fffc82 !important;
  cursor: pointer !important;
}
table.product-table td {
  padding: 0 !important;
}
tr.product-list td:not(:last-child) {
  border-bottom: none !important;
}
.ant-table-bordered.no-wrap .ant-table-thead > tr > th {
  white-space: nowrap !important;
}
.zui-table tbody td {
  border-bottom: solid 1px #ddefef !important;
  color: #333 !important;
  padding-right: 10px !important;
  text-shadow: 1px 1px 1px #fff !important;
  white-space: nowrap !important;
}
.table-head-word-break .ant-table-bordered .ant-table-thead > tr > th,
.table-head-word-break .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8 !important;
  word-break: break-word !important;
}
.customer-ant-footer-height .ant-table-footer {
  height: 45px !important;
}
.ant-header-font-13 .ant-table-thead > tr > th {
  font-size: 13px !important;
}
.ant-table-padding-left .ant-table-tbody > tr > td {
  padding-left: 16px !important;
}
.scrollable-table {
  border: none !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}
.scrollable-table thead th {
  border: none !important;
  padding: 10px !important;
  text-align: left !important;
  text-shadow: 1px 1px 1px #fff !important;
  white-space: nowrap !important;
  background-color: #f5f5f57d !important;
}
.scrollable-table tbody td {
  padding-right: 10px !important;
  text-shadow: 1px 1px 1px #fff !important;
  white-space: nowrap !important;
}
.table-color {
  background-color: #f5f5f57d !important;
}
.other-charges-footer :global .ant-table-footer {
  padding: 5px !important;
}
.login-field {
  margin-bottom: 25px;
}
.login-form {
  border-radius: 4px;
  padding: 50px;
  box-shadow: 0 2px 4px 0 #e5e5e5;
}
.message {
  max-width: 600px;
  margin: auto;
  white-space: pre-wrap;
}
.message.error {
  color: #a33;
  -webkit-user-select: text;
}
.message.error a {
  color: #a33;
}
.logout-btn {
  margin-left: 10vw;
}
.add-item {
  padding: 5px;
  margin: 5px 5px 5px 8px;
  cursor: pointer;
  width: 95%;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0px !important;
}
.drawer-action {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}
.full-screen-layout {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.fullScreenCenter {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.page-title {
  font-size: 40px;
  font-weight: 700;
}
.page-subtitle {
  font-size: 25px;
}
.error-text {
  font-size: 16px;
}
.step-title {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}
.action-disabled-section {
  pointer-events: none;
  filter: opacity(0.6);
}
.job-header-text {
  color: #339af6;
  font-weight: 500;
  padding-right: 15px;
}
.ant-menu-dark .ant-menu-item-group-title {
  color: rgba(255, 255, 255, 0.45) !important;
}
.sider-menu-wrapper {
  display: flex;
  color: #ffffff;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 15px 0;
}
.sider-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav-bar {
  width: 60px;
  position: relative;
}
.nav-bar__list {
  padding: 0 10px;
  list-style: none;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #2b2e31;
  overflow: auto;
}
.nav-bar__item {
  text-align: center;
}
.nav-bar__item button {
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.8rem 0 0.5rem;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  text-decoration: none;
  border-radius: 7px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}
.nav-bar__item button:focus {
  outline: none;
}
.nav-bar__item button.active.processing,
.nav-bar__item button.processing {
  background: rgba(255, 255, 255, 0.1);
}
.nav-bar__item button.active {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid;
}
.nav-bar__item button:hover {
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
}
.ant-drawer-wrapper-body {
  height: 100%;
}
ul.card-desc {
  list-style: none;
}
ul.card-desc li::before {
  content: •;
  content: \2022;
  color: #1890ff;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.25em;
}
.flex-center .ag-react-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.queue-email-link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.queue-email-link:hover {
  white-space: normal;
  overflow-wrap: break-word;
  overflow: auto;
}
.add-card {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.align-horizonatlly-column {
  display: flex;
  align-items: center;
  justify-content: center !important;
  flex-direction: column;
}
.align-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex {
  display: flex;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-flex-end {
  justify-content: flex-end;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.align-vertically {
  display: flex !important;
  align-items: center !important;
}
.primary-color {
  color: #1890ff;
}
.ant-select-dropdown-menu-item-selected {
  background: #b7e0f3 !important;
}
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.7, 0.7, 0.7);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.format-gallery img {
  border: 1px solid;
}
.format-gallery img.current {
  border: 2px double #1890ff;
}
.ant-form-item-explain {
  margin: 0 !important;
  min-height: 20px !important;
}
span.ant-layout-sider-zero-width-trigger {
  top: 70%;
  right: -25px;
  width: 25px;
  height: 36px;
}
.ant-layout-sider-zero-width .sider-live-chat {
  display: none;
}
.ant-timeline-item {
  margin-left: 2px !important;
}
.ql-bubble .ql-editor {
  min-height: 60px;
  max-height: 300px;
  border: 1px solid #ebeff2;
  border-radius: 4px;
}
.ql-editor table,
.quill-better-table {
  width: 100% !important;
}
.ql-snow .ql-editor {
  min-height: 240px;
  max-height: 350px;
  border-radius: 4px;
}
.tox-tinymce-inline {
  z-index: 1600 !important;
}
.mce-content-body img {
  width: 100%;
}
.text-muted {
  color: #8991a3;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-overflow:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}
.text-smaller,
time {
  font-size: 85%;
}
.unread-line {
  height: 3.2rem;
  margin: -0.3rem 3.2rem 0 0;
  margin-right: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
}
.unread-line:before {
  border-top: 1px solid #838480;
  content: '';
  display: block;
  position: relative;
  top: 1.4rem;
}
.unread-line-text {
  background: #fff;
  color: #1890ff;
  white-space: nowrap;
  display: inline-block;
  padding: 0.5rem 2.2rem;
  position: relative;
}
.email-form .ant-form-item {
  margin-left: -10px;
  border-bottom: 1px solid #f0f0f0;
}
.email-form .ant-form-item-label > label {
  color: gray;
  width: 100%;
}

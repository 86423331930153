.ant-table-wrapper.noPaddingTable .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-thead > tr > th {
  padding: 0px !important;
}

.ant-table-wrapper.smallPaddingTable .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-thead > tr > th {
  padding: 5px !important;
}

.small-font-table .ant-table-row > tr {
  font-size: 12px;
  height: 50px !important;
}

.zebra-table .ant-table-row:nth-child(even) {
  background: rgba(204, 204, 204, 0.15);
}

.bold-table-header .ant-table-thead > tr > th {
  background: white;
  font-weight: bold;
}

.noPaddingTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 0px !important;
}

.small-padding-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 1px !important;
}

// ant-table-row:nth-child(odd) {
//   background: #fff;
// }
.ant-table-wrapper.small-width-selection .ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  min-width: 35px !important;
}

table.bordered,
table.bordered th,
table.bordered td {
  border: 1px solid black;
}

table.template,
table.template th,
table.template td {
  border: 1px solid black;
  border-collapse: collapse;
  vertical-align: top;
}

table.template,
table.a4 {
  /*width : 827px;*/
  width: 100%;
  height: 1169px;
  border: 1px solid #ccc;
}

.border-left {
  border-left: 1px solid lightgrey;
}

table.doc-template,
table.doc-template tr,
table.doc-template td {
  border: 1px solid black;
  border-collapse: collapse;
  vertical-align: top;
}

table.doc-template td:hover {
  background-color: #fffc82;
  cursor: pointer;
}

table.product-table td {
  padding: 0 !important;
}

tr.product-list td:not(:last-child) {
  border-bottom: none;
}

.ant-table-bordered.no-wrap .ant-table-thead > tr > th {
  white-space: nowrap;
}

.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.zui-table {
  border: none;
  border-right: solid 1px #ddefef;
  border-collapse: separate;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}

.zui-table thead th {
  background-color: #ddefef;
  border: none;
  color: #336b6b;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}

.zui-table tbody td {
  border-bottom: solid 1px #ddefef;
  color: #333;
  padding-right: 10px;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}

.table-head-word-break {
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    word-break: break-word;
  }
}

.customer-ant-footer-height {
  .ant-table-footer {
    height: 45px;
  }
}

.ant-header-font-13 .ant-table-thead > tr > th {
  font-size: 13px;
}

.ant-table-padding-left .ant-table-tbody > tr > td {
  padding-left: 16px !important;
}

.strip-table tbody tr:nth-child(even) td {
  background-color: #f5f5f57d;
}

.scrollable-table {
  border: none;
  border-collapse: separate;
  border-spacing: 0;
}

.scrollable-table thead th {
  border: none;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
  background-color: #f5f5f57d;
}

.scrollable-table tbody td {
  padding-right: 10px;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}

.table-color {
  background-color: #f5f5f57d;
}

.other-charges-footer :global {
  .ant-table-footer {
    padding: 5px !important;
  }
}

.custom-strip-table > div.row:nth-child(odd) > div.col {
  background-color: #f5f5f57d;
}

.table-header-name {
  width: 10vw;
  padding: 8px;
  font-size: 14px;
}

.table-background {
  background-color: white;
}

.colgroup-1 {
  width: 10vw;
  min-width: 10vw;
}

.colgroup-2 {
  width: 30vw;
  min-width: 30vw;
}

.colgroup-3 {
  width: 35vw;
  min-width: 35vw;
}

.thead-title {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: #2b2b2a;
}

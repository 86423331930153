/*common*/
#root {
  height: 100%;
}
.ant-layout {
  height: 100%;
}
.absolute {
  position: absolute;
}
.width-100 {
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.pointer-none {
  pointer-events: none;
}
.shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.scroll {
  overflow: scroll;
}
.scroll-y {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 80vh;
}
.hidden {
  display: hidden;
}
.no-padding {
  padding: 0;
}
.relative {
  position: relative;
}
body {
  overflow-y: scroll;
}
.underline {
  text-decoration: underline;
  text-underline-position: under;
}
.content {
  padding: '0 10px';
  background: '#fff';
}
.flex-apart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-screen-layout {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.fullScreenCenter {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.page-title {
  font-size: 40px;
  font-weight: 700;
}

.page-subtitle {
  font-size: 25px;
}

.error-text {
  font-size: 16px;
}

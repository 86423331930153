.ant-table-wrapper.noPaddingTable .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-thead > tr > th {
  padding: 0px !important;
}

.ant-table-wrapper.smallPaddingTable .ant-table-bordered .ant-table-tbody > tr > td,
.ant-table-bordered .ant-table-thead > tr > th {
  padding: 5px !important;
}

.small-font-table .ant-table-row {
  font-size: 12px !important;
}

.bold-table-header .ant-table-thead > tr > th {
  font-weight: bold !important;
}

.noPaddingTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 0px !important;
}

.small-padding-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 7px !important;
  background: white !important;
}

// ant-table-row:nth-child(odd) {
//   background: #fff;
// }

.ant-table-wrapper.small-width-selection .ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  min-width: 35px !important;
}

table.bordered,
table.bordered th,
table.bordered td {
  border: 1px solid black !important;
}

table.template,
table.template th,
table.template td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
  vertical-align: top !important;
}

table.template,
table.a4 {
  /*width : 827px;*/
  width: 100% !important;
  height: 1169px !important;
  border: 1px solid #ccc !important;
}

.border-left {
  border-left: 1px solid lightgrey !important;
}

table.doc-template,
table.doc-template tr,
table.doc-template td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
  vertical-align: top !important;
}

table.doc-template td:hover {
  background-color: #fffc82 !important;
  cursor: pointer !important;
}

table.product-table td {
  padding: 0 !important;
}

tr.product-list td:not(:last-child) {
  border-bottom: none !important;
}

.ant-table-bordered.no-wrap .ant-table-thead > tr > th {
  white-space: nowrap !important;
}

.zui-table tbody td {
  border-bottom: solid 1px #ddefef !important;
  color: #333 !important;
  padding-right: 10px !important;
  text-shadow: 1px 1px 1px #fff !important;
  white-space: nowrap !important;
}

.table-head-word-break {
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8 !important;
    word-break: break-word !important;
  }
}

.customer-ant-footer-height {
  .ant-table-footer {
    height: 45px !important;
  }
}

.ant-header-font-13 .ant-table-thead > tr > th {
  font-size: 13px !important;
}

.ant-table-padding-left .ant-table-tbody > tr > td {
  padding-left: 16px !important;
}

.scrollable-table {
  border: none !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.scrollable-table thead th {
  border: none !important;
  padding: 10px !important;
  text-align: left !important;
  text-shadow: 1px 1px 1px #fff !important;
  white-space: nowrap !important;
  background-color: #f5f5f57d !important;
}

.scrollable-table tbody td {
  padding-right: 10px !important;
  text-shadow: 1px 1px 1px #fff !important;
  white-space: nowrap !important;
}

.table-color {
  background-color: #f5f5f57d !important;
}

.other-charges-footer :global {
  .ant-table-footer {
    padding: 5px !important;
  }
}

@import '~@ag-grid-community/core/dist/styles/ag-grid.scss';

@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

.ag-theme-alpine {
  .gray-text {
    color: #ada9a9;
  }
  .red-text {
    color: red;
  }

  .ag-icon-checkbox-unchecked,
  .ag-icon-checkbox-checked {
    font-size: 14px;
  }
}

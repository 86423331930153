.ant-menu-dark .ant-menu-item-group-title {
  color: rgba(255, 255, 255, 0.45) !important;
}

.sider-menu-wrapper {
  display: flex;
  color: #ffffff;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 15px 0;
}

.sider-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-bar {
  width: 60px;
  position: relative;
}
.nav-bar__list {
  padding: 0 10px;
  list-style: none;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #2b2e31;
  overflow: auto;
}
.nav-bar__item {
  text-align: center;
  button {
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.8rem 0 0.5rem;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    text-decoration: none;
    border-radius: 7px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    &:focus {
      outline: none;
    }
    &.active.processing,
    &.processing {
      background: rgba(255, 255, 255, 0.1);
    }
    &.active {
      color: rgb(255, 255, 255);
      background: rgba(255, 255, 255, 0.4);
      border: 2px solid;
      // &:before {
      //   content: '';
      //   position: absolute;
      //   left: -8px;
      //   top: 0;
      //   width: 7px;
      //   height: 100%;
      //   background: #fff;
      //   opacity: 0.7;
      //   border-radius: 3px;
      // }
    }
    &:hover {
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
    }
  }
}

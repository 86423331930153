.add-item {
  padding: 5px;
  margin: 5px 5px 5px 8px;
  cursor: pointer;
  width: 95%;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 0px !important;
}

.drawer-action {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}

ul.card-desc {
  list-style: none;
  li::before {
    content: •;
    content: \2022;
    color: #1890ff;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 1.25em;
  }
}
.flex-center .ag-react-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.queue-email-link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    white-space: normal;
    overflow-wrap: break-word;
    overflow: auto;
  }
}
.add-card {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #aaa7a7 !important;
}

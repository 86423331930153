.login-field {
  margin-bottom: 25px;
}

.login-form {
  border-radius: 4px;
  padding: 50px;
  box-shadow: 0 2px 4px 0 #e5e5e5;
}

.message {
  max-width: 600px;
  margin: auto;
  white-space: pre-wrap;

  &.error {
    color: #a33;
    -webkit-user-select: text;

    a {
      color: #a33;
    }
  }
}

.logout-btn {
  margin-left: 10vw;
}

@import './static/style/1-tools/typography.less';
@import './static/style/2-pages/page.css';
@import './static/style/2-pages/common.css';
@import './static/style/2-pages/verification.less';
@import './static/style/1-tools/table.less';
@import './static/style/profile-menu';
@import './static/style/card';
@import './static/style/table';
@import './static/style/2-pages/auth.less';
@import './static/style/1-tools/form-components.less';
@import './static/style/2-pages/error-page.less';
@import './static/style/2-pages/identification.less';
@import './static/style/2-pages/sidebar.less';
@import './static/style/drawer.less';
@import './static/style/settings.less';

//commonly used styles

@primary-color: #1890ff;
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.align-horizonatlly-column {
  display: flex;
  align-items: center;
  justify-content: center !important;
  flex-direction: column;
}

.align-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex {
  display: flex;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.align-vertically {
  display: flex !important;
  align-items: center !important;
}

.primary-color {
  color: @primary-color;
}
.ant-select-dropdown-menu-item-selected {
  background: #b7e0f3 !important;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.7, 0.7, 0.7);
  }

  // 20% {
  //   -webkit-transform: scale3d(1.1, 1.1, 1.1);
  //   transform: scale3d(1.1, 1.1, 1.1);
  // }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  // 60% {
  //   opacity: 1;
  //   -webkit-transform: scale3d(1.03, 1.03, 1.03);
  //   transform: scale3d(1.03, 1.03, 1.03);
  // }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.format-gallery {
  img {
    border: 1px solid;
  }
  img.current {
    border: 2px double @primary-color;
  }
}

.ant-form-item-explain {
  margin: 0 !important;
  min-height: 20px !important;
}

//Customizing behavior of 0 width sider trigger to appear lower than it appears now
span.ant-layout-sider-zero-width-trigger {
  top: 70%;
  right: -25px;
  width: 25px;
  height: 36px;
}
.ant-layout-sider-zero-width .sider-live-chat {
  display: none;
}
.ant-timeline-item {
  margin-left: 2px !important;
}
.ql-bubble .ql-editor {
  min-height: 60px;
  max-height: 300px;
  border: 1px solid #ebeff2;
  border-radius: 4px;
}
.ql-editor table,
.quill-better-table {
  width: 100% !important;
}
.ql-snow .ql-editor {
  min-height: 240px;
  max-height: 350px;
  border-radius: 4px;
}

.tox-tinymce-inline {
  z-index: 1600 !important;
}
.mce-content-body img {
  width: 100%;
}
.text-muted {
  // color: #999;
  // color: rgba(0, 0, 0, 0.45);
  color: rgb(137, 145, 163);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}
.text-smaller {
  font-size: 85%;
}
time {
  &:extend(.text-smaller);
}

.unread-line {
  height: 3.2rem;
  margin: -0.3rem 3.2rem 0 0;
  margin-right: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
}
.unread-line:before {
  border-top: 1px solid #838480;
  content: '';
  display: block;
  position: relative;
  top: 1.4rem;
}

.unread-line-text {
  background: #fff;
  color: @primary-color;
  white-space: nowrap;
  display: inline-block;
  padding: 0.5rem 2.2rem;
  position: relative;
}

//.ant-form-item-label > label {
.email-form {
  .ant-form-item {
    margin-left: -10px;
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-form-item-label > label {
    color: gray;
    width: 100%;
  }
}

@form-item-margin-bottom: 5px;@form-vertical-label-padding: 0px;@breadcrumb-separator-color: #ffffff;@breadcrumb-link-color: #ffffff;